import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.custom.palette.lightBlue,
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(8, 0, 8),
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
    },
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    lineHeight: 1.2,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
  },
  description: {
    fontSize: 16,
    lineHeight: 2,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
  },
  input: {
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
  },
  resetButton: {
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2.5),
    }
  },
  form: {
    maxWidth: 570,
    marginBlock: theme.spacing(4),
  }
}));

export default useStyles;