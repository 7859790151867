
import React, { memo } from 'react';
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import * as connectAPI from '@services/api-connect'
import ContainedButton from '@components/UI/Buttons/ContainedButton'
import OutlinedButton from '@components/UI/Buttons/OutlinedButton'
import FansTextField from '@components/UI/FansTextField'
import { PHONE_VALID } from '@utils/constants/validations'
import MESSAGES from '@utils/constants/messages'
import { showErrorToast, showSuccessToast } from '@utils/helpers/toast'
import getConnectErrorMessage from '@utils/errors/getConnectErrorMessage'
import useCommonStyles from '@styles/use-styles'

import useStyles from './styles';

const schema = yup.object().shape({
  phone: PHONE_VALID,
});

const RemoveAccountForm = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const params = {
        subject: 'Experiwear Account Deletion Request',
        email: '',
        fullName: '',
        question: `Delete account with this phone number: ${data.phone}`
      }
  
      await connectAPI.connect(params);
      showSuccessToast(MESSAGES.CONNECT_SUCCESS)
    } catch (error) {
      if (error.response) {
        const { data: { code } } = error.response;
        showErrorToast(getConnectErrorMessage(code))
      }
    }
    resetHandler();
  };

  const resetHandler = () => {
    setValue('phone', '')
  }

  return (
    <main className={classes.root}>
      <div className={clsx(classes.container, commonClasses.containerWidth)}>
        <Typography className={classes.title}>
            Delete your Experiwear account
        </Typography>
        <Typography className={classes.description}>
            Enter your phone number to request deletion of your account.
        </Typography>
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
        >
            <Controller
                as={<FansTextField />}
                required
                name='phone'
                type='tel'
                placeholder='Enter your phone number'
                error={errors.phone?.message}
                className={classes.input}
                control={control}
                defaultValue=''
            />
            <div className={classes.buttonContainer}>
                <OutlinedButton
                    className={classes.resetButton}
                    onClick={resetHandler}
                >
                    RESET
                </OutlinedButton>
                <ContainedButton type='submit'>
                    SUBMIT
                </ContainedButton>
            </div>
        </form>
      </div>
    </main>
  )
};

export default memo(RemoveAccountForm);
