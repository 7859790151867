import React from 'react';

import Layout from '@Layout';
import RemoveAccountForm from '@containers/RemoveAccountForm';

const RemovePage = () => (
  <Layout>
    <RemoveAccountForm />
  </Layout>
);

export default RemovePage;
